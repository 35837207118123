import React, { useMemo } from "react";
import moment from "moment";

export default function WeekSelector({
  selectedYear,
  onDone, // The function to call when a week is chosen
}) {
  // figure out how many ISO weeks are in that year
  const totalWeeksInYear = useMemo(() => {
    return moment(`${selectedYear}`, "YYYY").isoWeeksInYear(); // 52 or 53
  }, [selectedYear]);

  // figure out the user's *real* current year & week
  const currentYear = moment().year();

  // Create an array of week numbers [1.. totalWeeksInYear]
  const weeksArray = Array.from({ length: totalWeeksInYear }, (_, i) => i + 1);

  // Helper to check if selected year/week is last/current/next
  const getWeekCategory = (weekNum) => {
    // Moment representing the start of the current (real) week
    const currentWeekMoment = moment().startOf("isoWeek");
    // Moment representing the start of the selected week of `selectedYear`
    const selectedWeekMoment = moment()
      .year(selectedYear)
      .isoWeek(weekNum)
      .startOf("isoWeek");

    // Calculate the difference in full weeks
    const diffWeeks = selectedWeekMoment.diff(currentWeekMoment, "weeks");

    if (diffWeeks === 0) return "current";
    if (diffWeeks === -1) return "last";
    if (diffWeeks === 1) return "next";
    return null;
  };
  // Helper to get the date range for each week
  const getWeekRangeString = (weekNum) => {
    const startOfWeek = moment()
      .year(selectedYear)
      .isoWeek(weekNum)
      .startOf("isoWeek");
    const endOfWeek = moment()
      .year(selectedYear)
      .isoWeek(weekNum)
      .endOf("isoWeek");
    return startOfWeek.format("D MMM") + " - " + endOfWeek.format("D MMM");
  };

  // Generate all week “cards”
  return (
    <div className="p-4 flex flex-wrap justify-between gap-3">
      {weeksArray.map((weekNum) => {
        const weekRangeLabel = getWeekRangeString(weekNum);
        const category = getWeekCategory(weekNum);

        // Colors based on category
        let bgColor = "bg-white"; // default
        let borderColor = "border-gray-400";
        if (category === "current") {
          bgColor = "bg-[#2136d4] text-white";
          borderColor = "border-[#2136d4]";
        } else if (category === "last") {
          bgColor = "bg-red-100";
          borderColor = "border-red-200";
        } else if (category === "next") {
          bgColor = "bg-gray-100";
          borderColor = "border-gray-300";
        }

        // On click, produce the from/to data + call onDone()
        const handleClick = () => {
          const from = moment()
            .year(selectedYear)
            .isoWeek(weekNum)
            .startOf("isoWeek")
            .toISOString();
          const to = moment()
            .year(selectedYear)
            .isoWeek(weekNum)
            .endOf("isoWeek")
            .toISOString();

          onDone({
            from,
            to,
            label: `Week ${weekNum} (${weekRangeLabel})`,
          });
        };

        return (
          <div
            key={weekNum}
            onClick={handleClick}
            className={`relative w-[6rem] h-[5rem] rounded-md border py-4 ${borderColor} ${bgColor} cursor-pointer flex items-center justify-center`}
          >
            {/* The top-right corner tag */}
            {category && (
              <div
                className={`absolute top-1 right-1 text-[10px] font-medium ${
                  category === "current"
                    ? "text-white"
                    : category === "last"
                    ? "text-red-600"
                    : "text-gray-600"
                }`}
              >
                {category}
              </div>
            )}

            {/* The main content (week number, date range, optional year) */}
            <div className="flex flex-col items-center justify-center text-center">
              <div className="font-bold text-xs leading-5">Week {weekNum}</div>
              <div className="text-2xs leading-4">{weekRangeLabel}</div>

              {/* Show selectedYear only if it’s not the *real* current year */}
              {selectedYear !== currentYear && (
                <div className="text-[10px] leading-3 text-gray-700 mt-1">
                  {selectedYear}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
