import moment from "moment";
import React from "react";
import { FaAngleRight } from "react-icons/fa";

let arrowButtonClickTimeOut;
export default function DateCarousel({
  eachRangeDaysCount = 1,
  onChangeCallback = (e) => {
    console.log(e);
  },
  rightArrowIcon = (
    <div className="flex-center size-8 bg-blue-100 rounded-full">
      <FaAngleRight />
    </div>
  ),
  dateRange = [moment().startOf("day"), moment().endOf("day"), "Today"], // always pass moment objects
}) {
  const onArrowClick = (arrow) => {
    if (arrowButtonClickTimeOut) {
      clearTimeout(arrowButtonClickTimeOut);
    }

    arrowButtonClickTimeOut = setTimeout(() => {
      let from, to;
      if (arrow === "left") {
        from = dateRange[0].subtract(eachRangeDaysCount, "days");
        to = dateRange[1].subtract(eachRangeDaysCount, "days");
      } else {
        from = dateRange[0].add(eachRangeDaysCount, "days");
        to = dateRange[1].add(eachRangeDaysCount, "days");
      }

      const label = getDateRangeLabel(from, to, eachRangeDaysCount);

      onChangeCallback([from, to, label]);
    }, 50);
  };

  return (
    <div className="flex w-full items-center justify-between gap-4">
      {/* left arrow button */}
      <div
        className="text-[#2136d4] cursor-pointer rotate-180"
        onClick={() => onArrowClick("left")}
      >
        {rightArrowIcon}
      </div>

      {/* date range label */}
      <div className="text-black font-medium">{dateRange[2]}</div>

      {/* right arrow button */}
      <div
        className="text-[#2136d4] cursor-pointer "
        onClick={() => onArrowClick("right")}
      >
        {rightArrowIcon}
      </div>
    </div>
  );
}

const getDateRangeLabel = (from, to, eachRangeDaysCount) => {
  let label;
  // if eachRangeDaysCount is 1, then it is a single day label
  if (eachRangeDaysCount === 1) {
    label = from.format("Do MMM, YYYY");
  } else {
    label = `${from.format("Do MMM")} - ${to.format("Do MMM, YYYY")}`;
  }

  // check predefined labels
  for (let predefinedLabel of predefinedLabels) {
    if (
      from.isSame(predefinedLabel[0], "day") &&
      to.isSame(predefinedLabel[1], "day")
    ) {
      label = predefinedLabel[2];
    }
  }

  // default label
  return label;
};

const predefinedLabels = [
  [moment().startOf("day"), moment().endOf("day"), "Today"],
  [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
    "Yesterday",
  ],
  [
    moment().add(1, "days").startOf("day"),
    moment().add(1, "days").endOf("day"),
    "Tomorrow",
  ],
];
