import React, { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import StoreContext from "../context/StoreContext";
import { FaCaretDown } from "react-icons/fa";

// manage the selection of area (city/hub) universal filter

export default function SelectAreaFilterModal({
  parentStates,
  setParentStates,
}) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      SelectAreaFilterModal: {
        isRender: false,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.SelectAreaFilterModal.isRender && (
        <Modal handleClose={handleClose} />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose }) => {
  const [states, setStates] = useState({});
  const { storeStates, setStoreStates } = useContext(StoreContext);

  // serviceableCities

  return (
    <div className="fixed top-0 z-[98] flex items-start justify-end right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "-100%" }}
        animate={{ y: "0%" }}
        exit={{ y: "-100%" }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center w-full md:w-[30rem] z-[2] flex-col rounded-b-xl md:rounded-br-xl bg-[white] "
      >
        <div className=" w-full flex items-center justify-between p-4 border-b border-gray-200 pb-2 ">
          <div className="flex flex-col ">
            <p className="text-[1.125rem] font-[500] ">Select hubs</p>
          </div>
          <div onClick={handleClose} className="cursor-pointer ">
            <RxCross1 />
          </div>
        </div>
        <div className=" w-full flex flex-col px-4 py-4 gap-4 max-h-[80vh] overflow-scroll flex-shrink-0 text-[.875rem] ">
          <div
            onClick={() => {
              setStoreStates((p) => ({
                ...p,
                universalFilter: {
                  city: "all",
                  hub: "all",
                },
              }));
              handleClose();
            }}
            className="flex items-center cursor-pointer p-2 flex-shrink-0   text-[.875rem] font-[500] hover:bg-blue-50 hover:text-[#2136d4]  border border-gray-200 rounded-md   capitalize w-full justify-between "
          >
            All Cities
          </div>
          {/*  */}
          {Object.values(storeStates?.serviceableCities)?.map((city) => {
            return (
              <motion.div
                key={city.city}
                initial={{ height: "2.5rem" }}
                animate={{
                  height: states[city.city]?.unCollapsed
                    ? city?.hubIds?.length * 2 + 3 + 2 + "rem"
                    : "2.5rem",
                }}
                transition={{ ease: "easeInOut", duration: 0.3 }}
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    [city.city]: {
                      unCollapsed: !p[city.city]?.unCollapsed,
                    },
                  }));
                }}
                className="flex flex-col flex-shrink-0  items-center justify-between overflow-hidden  border border-gray-200 px-2 rounded-md py-2 w-full "
              >
                <div className="flex items-center cursor-pointer text-[.875rem] font-[500] mb-[.5rem] capitalize w-full justify-between ">
                  {city.city}
                  <FaCaretDown />
                </div>
                <div className="flex flex-col w-full pl-2  flex-shrink-0  ">
                  <div
                    onClick={() => {
                      setStoreStates((p) => ({
                        ...p,
                        universalFilter: {
                          city: city.city,
                          hub: "all",
                        },
                      }));
                      handleClose();
                    }}
                    className="py-1 cursor-pointer h-[2rem] flex-shrink-0  text-[.8125rem] flex items-center px-2 rounded-md hover:bg-blue-50 hover:text-[#2136d4] "
                  >
                    All hubs
                  </div>
                  {city?.hubIds?.map((hub, index) => {
                    return (
                      <div
                        key={hub}
                        onClick={() => {
                          setStoreStates((p) => ({
                            ...p,
                            universalFilter: {
                              city: city.city,
                              hub: hub,
                            },
                          }));
                          handleClose();
                        }}
                        className="py-1 cursor-pointer flex-shrink-0  h-[2rem] text-[.8125rem] flex items-center px-2 rounded-md hover:bg-blue-50 hover:text-[#2136d4] "
                      >
                        {hub}
                      </div>
                    );
                  })}
                </div>
              </motion.div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};
