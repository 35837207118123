import { createContext, useContext, useEffect, useState } from "react";

const StoreContext = createContext();

export const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [orders, setOrders] = useState({
    isLoading: false,
    orders: [],
    setOrders: 0,
  });

  const [storeStates, setStoreStates] = useState({
    defaultHub: null,
    hubs: [],
    defaultLocation: null,
    navHeadline: {
      heading: "",
      subHeading: "",
      component: null,
    },
    universalFilter: {
      city: "all",
      hub: "all",
    },
    hubsByCity: [],
    defaultDecorDiscount: {
      isEnabledDiscount: true,
      isEnableDiscountEditModal: false,
      discountValue: 15,
      maxOfferAmountGain: 1000,
      minOrderValue: 3499,
      isRange2: true,
      range2MinOrderValue: 1499,
      range2MaxOrderValue: 3498,
      range2DiscountValue: 10,
    },
    isGoogleMapsScriptLoaded: false,
    decorCategoryList: [],
    addonCategoryList: [],
    allHubs: [],
    hubCityPairs: {}, // {hubId: city, ....}
    admins: [], // will contain all the admin list // { _id , userName }
    hubBoundaries: null,
    serviceableCities: {}, // will contain the serviceable cities and their information
    webSourceFrom: "web", // app | web
  });

  //this will be used as storing and using some data at different routes temporarily
  const [tempData, setTempData] = useState({});

  // -------------------- constants --------------------
  const BaseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Load the Google Maps API script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
    script.defer = true;
    document.head.appendChild(script);

    // Set the global variable to true once the script is loaded
    script.onload = () => {
      setStoreStates((p) => ({
        ...p,
        isGoogleMapsScriptLoaded: true,
      }));
    };

    // Cleanup to prevent memory leaks
    return () => {
      document.head.removeChild(script);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <StoreContext.Provider
      value={{
        BaseUrl,
        orders,
        setOrders,
        storeStates,
        setStoreStates,
        tempData,
        setTempData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
