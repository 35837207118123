import moment from "moment";
import React from "react";
import { FaCalendar } from "react-icons/fa";
import { Link } from "react-router-dom";

// Define the FixedStats component
export default function FixedStats({
  states: { fixedStatsData },
  navigationUrl,
  updateUniversalFilter,
}) {
  // Define an array of statistics to display
  const stats = [
    {
      period: "Yesterday",
      icon: FaCalendar,
      label: "Converted Users",
      value: `${fixedStatsData?.yesterday?.convertedUsers || 0} / ${
        fixedStatsData?.yesterday?.newUsers || 0
      }`,
      linkProps: {
        queries: ["convertedUsers"],
        label: "Converted Users",
        sortBy: "registrationDesc",
        dateRange: [
          moment().startOf("day").subtract(1, "days").toDate(),
          moment().endOf("day").subtract(1, "days").toDate(),
          "Yesterday",
        ],
      },
      bgColor: "bg-purple-50",
      borderColor: "border-purple-200",
      textColor: "text-purple-800",
    },
    {
      period: "This Week",
      icon: FaCalendar,
      label: "Converted Users",
      value: `${fixedStatsData?.thisWeek?.convertedUsers || 0} / ${
        fixedStatsData?.thisWeek?.newUsers || 0
      }`,
      linkProps: {
        queries: ["convertedUsers"],
        label: "Converted Users",
        sortBy: "registrationDesc",
        dateRange: [
          moment().startOf("week").toDate(),
          moment().endOf("week").toDate(),
          "This Week",
        ],
      },
      bgColor: "bg-blue-50",
      borderColor: "border-blue-200",
      textColor: "text-blue-800",
    },
    {
      period: "Last Week",
      icon: FaCalendar,
      label: "Converted Users",
      value: `${fixedStatsData?.lastWeek?.convertedUsers || 0} / ${
        fixedStatsData?.lastWeek?.newUsers || 0
      }`,
      linkProps: {
        queries: ["convertedUsers"],
        label: "Converted Users",
        sortBy: "registrationDesc",
        dateRange: [
          moment().startOf("week").subtract(1, "week").toDate(),
          moment().endOf("week").subtract(1, "week").toDate(),
          "Last Week",
        ],
      },
      bgColor: "bg-green-50",
      borderColor: "border-green-200",
      textColor: "text-green-800",
    },
    {
      period: "This Month",
      icon: FaCalendar,
      label: "Converted Users",
      value: `${fixedStatsData?.thisMonth?.convertedUsers || 0} / ${
        fixedStatsData?.thisMonth?.newUsers || 0
      }`,
      linkProps: {
        queries: ["convertedUsers"],
        label: "Converted Users",
        sortBy: "registrationDesc",
        dateRange: [
          moment().startOf("month").toDate(),
          moment().endOf("month").toDate(),
          "This Month",
        ],
      },
      bgColor: "bg-red-50",
      borderColor: "border-red-200",
      textColor: "text-red-800",
    },
  ];

  return (
    <div className="w-full flex flex-col">
      {/* Decorative Gradient Line */}
      <div className="w-[10rem] h-1 rounded-full bg-gradient-to-r from-[#2136d4] my-4 ml-1 to-transparent"></div>

      {/* Grid Container for StatCards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 px-4">
        {stats.map((stat) => (
          <StatCard
            key={stat.period}
            icon={stat.icon}
            label={stat.label}
            value={stat.value}
            linkProps={stat.linkProps}
            bgColor={stat.bgColor}
            borderColor={stat.borderColor}
            textColor={stat.textColor}
            navigationUrl={navigationUrl}
            updateUniversalFilter={updateUniversalFilter}
            period={stat.period}
          />
        ))}
      </div>
    </div>
  );
}

// Define the StatCard component
const StatCard = ({
  icon: Icon,
  label,
  value,
  linkProps,
  bgColor,
  borderColor,
  textColor,
  navigationUrl,
  updateUniversalFilter,
  period,
}) => (
  <Link
    to={navigationUrl(linkProps)}
    onClick={updateUniversalFilter}
    className={`flex flex-col items-start justify-between p-2 flex-shrink-0 whitespace-nowrap flex-1 rounded-lg shadow-sm ${bgColor} border ${borderColor} hover:bg-opacity-75 transition-colors`}
  >
    {/* Header: Icon and Period */}
    <div className="flex items-center gap-2 mb-2 text-xs font-medium text-gray-700">
      <Icon className={`${textColor} text-sm`} />
      <span>{period}</span>
    </div>

    {/* Value Display */}
    <div
      className={`text-sm font-semibold flex items-center gap-1  ${textColor}`}
    >
      <div className="font-normal">Conversion: </div>
      {value}
    </div>
  </Link>
);
