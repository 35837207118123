import React from "react";
import { useStoreContext } from "../../../context/StoreContext";

export default function CitySelectionC1({
  selectedCity,
  callback = (selectedCity) => {
    console.log("selectedCity", selectedCity);
  },
  size = "xs", // xs, sm, base, lg, xl
  chipsCount = 3,
  isNoWrap = true,
  chipsGap = 2,
  styles,
}) {
  const {
    storeStates: { serviceableCities },
  } = useStoreContext();

  const sizingClassName = {
    xs: " text-xs py-1 px-1 min-w-[4rem] ",
    sm: " text-sm py-1 px-2  min-w-[5rem]",
    base: " text-base py-2 px-3 ",
    lg: " text-lg py-3 px-4 ",
    xl: " text-xl py-4 px-5 ",
  };

  const cities = Object.keys(serviceableCities);

  const isSelectedCityIsInSelectChips =
    cities.slice(chipsCount).includes(selectedCity) || selectedCity === "other";

  return (
    <div
      className={` flex ${
        isNoWrap && " flex-nowrap "
      }  overflow-scroll gap-${chipsGap}  flex-shrink-0 w-full  `}
      style={styles}
    >
      <div
        onClick={() => callback("all")}
        className={` ${
          selectedCity === "all"
            ? " blue-gradient "
            : " border-gray-200 cursor-pointer bg-gray-50 border "
        }  rounded-full capitalize font-medium flex-shrink-0 text-center ${
          sizingClassName[size]
        }  `}
      >
        All
      </div>
      {cities?.slice(0, chipsCount).map((city) => {
        return (
          <div
            key={city}
            onClick={() => {
              !(city === selectedCity) && callback(city);
            }}
            className={` ${
              city === selectedCity
                ? " blue-gradient "
                : " border-gray-200 cursor-pointer bg-gray-50 border "
            }  rounded-full capitalize font-medium   text-center flex-shrink-0 ${
              sizingClassName[size]
            }  `}
          >
            {city}
          </div>
        );
      })}

      <select
        className={` ${
          isSelectedCityIsInSelectChips
            ? " blue-gradient "
            : " border-gray-200 cursor-pointer bg-gray-50 border "
        }  rounded-full capitalize font-medium text-center flex-shrink-0 ${
          sizingClassName[size]
        }  `}
        onChange={(e) => callback(e.target.value || "all")}
        value={selectedCity}
      >
        <option value={"other"}>Other</option>
        {cities.slice(chipsCount).map((city) => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>
    </div>
  );
}
