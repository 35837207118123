import React, { Suspense, lazy } from "react";
import OrderCounts from "../../order/v3/order_counts/order_counts_subc/OrderCounts/OrderCounts";
import OrderCityAnalyticsCard from "./c/OrderCityAnalyticsCard";
import UserCityAnalyticsChart from "./c/UserCityAnalyticsChart";
import UserDateAnalyticsChart from "./c/UserDateAnalyticsChart";
import UserConversionChart from "./c/UserConversionChart";
import UserAnalyticsCard from "../../userComponents/c/userAnalyticsCard/UserAnalyticsCard";
const OrderAnalyticsCard = lazy(() => import("./c/OrderAnalyticsCard"));

export default function ChartsIndex() {
  return (
    <div className="flex flex-wrap gap-3 fix-screen py-[6rem] px-4 overflow-scroll bg-blue-50 ">
      <div className="w-full lg:w-[48%]  flex-grow">
        <Suspense fallback={<div className="loader w-[1rem]"> </div>}>
          <OrderCounts className="h-full" />
        </Suspense>
      </div>
      <div className="  w-full lg:w-[48%] flex-grow ">
        <Suspense fallback={<div className="loader w-[1rem]"> </div>}>
          <UserAnalyticsCard />
        </Suspense>
      </div>
      <div className=" w-full lg:w-[48%]  flex-grow">
        <OrderAnalyticsCard />
      </div>
      <div className=" w-full lg:w-[48%]  flex-grow">
        <OrderCityAnalyticsCard />
      </div>
      <div className=" w-full lg:w-[48%]  flex-grow">
        <UserCityAnalyticsChart />
      </div>
      <div className=" w-full lg:w-[48%]  flex-grow">
        <UserDateAnalyticsChart />
      </div>
      <div className=" w-full lg:w-[48%]  flex-grow">
        <UserConversionChart />
      </div>
    </div>
  );
}
