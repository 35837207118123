import { useCallback } from "react";
import { useStoreContext } from "../../../../../../context/StoreContext";
import { Link } from "react-router-dom";
import {
  FaRegCalendar,
  FaCalendarAlt,
  FaListOl,
  FaCheckCircle,
  FaSyncAlt,
  FaHourglassHalf,
  FaAngleRight,
} from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { BodyComp, HeaderComp } from "./OrderCounts_subC";
import useOrderCountData from "../../../../../../hooks/order/useOrderCountData";
import moment from "moment";
import DateCarousel from "../../../../../../assets/dateRangeSelectors/DateCarousel";
import CitySelectionC1 from "../../../../../citySelectionComponents/c1/CitySelectionC1";

export default function OrderCounts({ returnPendingOrders = 0, className }) {
  const { storeStates, setStoreStates } = useStoreContext();

  const {
    data,
    isLoading,
    city,
    hub,
    dateRange,
    refetch,
    setCity,
    setHub,
    setDateRange,
    setGroupingFieldName,
    groupingFieldName,
  } = useOrderCountData({
    dateRange: {
      label: "Today",
      from: moment().startOf("day").toDate(),
      to: moment().endOf("day").toDate(),
    },
    countTypes: [
      "totalScheduledOrders",
      "totalPunchedOrders",
      "completedOrdersSitDr",
      "outForServiceOrdersSitDr",
      "todayDelayedOrders",
      "remainingOrdersSitDr",
      "sameDayService",
      "assignedWithSanta",
    ],
    groupingFieldName: "city",
  });

  const updateUniversalFilter = useCallback(() => {
    if (city !== "others") {
      setStoreStates((prev) => ({
        ...prev,
        universalFilter: {
          city,
          hub,
        },
      }));
    }
  }, [city, hub, setStoreStates]);

  const navigationUrl = useCallback(
    (filterationRules, label, isShiftWiseFilteration = true, options) => {
      return `/order/order-list/custom?options=${encodeURIComponent(
        JSON.stringify({
          filterationRules,
          dateFrom: dateRange.from,
          dateTo: dateRange.to,
          dateRangeLabel: dateRange.label,
          label,
          city,
          isShiftWiseFilteration,
          isGroupByOrderDate:
            typeof options?.isGroupByOrderDate === "boolean"
              ? options?.isGroupByOrderDate
              : false,
          isAscendingOrder:
            true || typeof options?.isAscendingOrder === "boolean"
              ? options?.isAscendingOrder
              : true,
        })
      )}`;
    },
    [city, dateRange.from, dateRange.label, dateRange.to]
  );

  return (
    <div
      className={`flex flex-col flex-1 border border-gray-200 bg-white rounded-lg py-4 ${className}`}
    >
      {/* Header Section */}
      <div className="w-full flex flex-wrap gap-2 justify-between items-center border-b px-4 pb-2 font-[600] mb-4">
        {/* Title & Filters */}
        <div className="flex flex-col">
          <h1 className="text-base md:text-lg font-[500] text-gray-800">
            Order Analytics
          </h1>
          <h2 className="text-2xs md:text-xs mb-2 text-gray-500 capitalize">
            {city !== "all" ? city : ""}
            {hub !== "all" ? ` - ${hub}` : ""}
          </h2>

          {/* City listing: horizontal scroll container */}

          <CitySelectionC1
            selectedCity={city}
            callback={(selectedCity) => {
              setCity(selectedCity);
              setHub("all");
              setGroupingFieldName(selectedCity === "all" ? "city" : "name");
            }}
          />
        </div>

        {/* Right side actions (Reload + DateRange) */}
        <div className="gap-4 flex items-center flex-shrink-0">
          {isLoading ? (
            <div className="loader w-[1rem]" />
          ) : (
            <IoReload
              onClick={refetch}
              className="cursor-pointer text-[1.125rem] text-gray-600 hover:text-gray-800 transition-colors"
              title="Refresh Data"
            />
          )}

          <div
            onClick={() => {
              window.setDateRange({
                type: "userAnalyticsCardDateRange",
                callBack: setDateRange,
              });
            }}
            className="border cursor-pointer bg-white shadow-sm rounded-md flex gap-2 items-center text-[.825rem] px-2 py-1 hover:bg-gray-100 transition-colors"
            title="Select Date Range"
          >
            {dateRange.label} <FaRegCalendar />
          </div>
        </div>
      </div>

      {moment(dateRange.from).isSame(moment(dateRange.to), "day") && (
        <div className="w-full flex-center  pb-4  ">
          <div className="w-[30rem] max-w-[80vw]">
            <DateCarousel
              {...{
                rightArrowIcon: (
                  <div className="flex-center size-6 text-xs bg-blue-100 rounded-full">
                    <FaAngleRight />
                  </div>
                ),
                dateRange: [
                  moment(dateRange.from),
                  moment(dateRange.to),
                  dateRange.label,
                ],
                onChangeCallback: (dR) => {
                  setDateRange({
                    from: dR[0].toDate(),
                    to: dR[1].toDate(),
                    label: dR[2],
                  });
                },
              }}
            />
          </div>
        </div>
      )}

      {/* Main Content */}
      {/* On large screens: "row" (side-by-side), on smaller screens: "col" (stacked). */}
      <div className="px-4 flex flex-col lg:flex-row gap-4">
        {/* Punched Orders Card */}
        <OrderCountsSubCard
          className="lg:flex-1"
          HeaderComp={() => (
            <HeaderComp
              title={
                <div className="flex items-center whitespace-nowrap gap-2">
                  <FaListOl className="text-blue-600 text-lg" />
                  <span>Punched Orders</span>
                </div>
              }
              titleClass="text-base md:text-lg"
              totalClass="text-xl md:text-2xl font-bold text-blue-700"
              total={data?.totalPunchedOrders?.count || 0}
              to={navigationUrl(
                data?.totalPunchedOrders?.filterationRules,
                "Punched Orders",
                true,
                {
                  isGroupByOrderDate: true,
                  isAscendingOrder: false,
                }
              )}
            />
          )}
          SubHeaderComp={() => (
            <div className="flex gap-2 flex-nowrap mt-2 text-xs">
              {[
                {
                  title: "Same day",
                  total: data?.sameDayService?.count,
                  to: navigationUrl(
                    data?.sameDayService?.filterationRules,
                    "Same day service",
                    true,
                    {
                      isGroupByOrderDate: true,
                      isAscendingOrder: false,
                    }
                  ),
                },
                {
                  title: "Santa",
                  total: data?.assignedWithSanta?.count,
                  to: navigationUrl(
                    data?.assignedWithSanta?.filterationRules,
                    "Santa Assigned",
                    true,
                    {
                      isGroupByOrderDate: true,
                      isAscendingOrder: false,
                    }
                  ),
                },
              ].map((curr, index) => (
                <PunchedOrdersSubHeaderComp
                  key={index}
                  item={curr}
                  className={
                    curr?.total === 0 ? "hidden" : "bg-blue-50 border-blue-200"
                  }
                />
              ))}
            </div>
          )}
          BodyItems={data?.totalPunchedOrders?.orders?.map((curr) => ({
            ...curr,
            to: `/order/order-list/custom?options=${encodeURIComponent(
              JSON.stringify({
                filterationRules: data?.totalPunchedOrders?.filterationRules,
                label: "Punched Orders",
                dateFrom: dateRange.from,
                dateTo: dateRange.to,
                dateRangeLabel: dateRange.label,
                city:
                  groupingFieldName === "city"
                    ? curr?.title
                    : storeStates?.hubs?.find(
                        (hub) => hub?.name === curr?.title
                      )?.city,
              })
            )}`,
          }))}
          BodyComp={BodyComp}
        />

        {/* Scheduled Orders Card */}
        <OrderCountsSubCard
          className="lg:flex-1"
          HeaderComp={() => (
            <HeaderComp
              title={
                <div className="flex items-center gap-2">
                  <FaCalendarAlt className="text-blue-600 text-lg" />
                  <span>Scheduled Orders</span>
                </div>
              }
              titleClass="text-base md:text-lg"
              totalClass="text-xl md:text-2xl font-bold text-blue-700"
              total={data?.totalScheduledOrders?.count || 0}
              to={navigationUrl(
                data?.totalScheduledOrders?.filterationRules,
                "Scheduled Orders",
                true
              )}
            />
          )}
          SubHeaderComp={() => {
            // "Completed", "Ongoing", "Remaining", "Scheduled" line
            const totalScheduled = data?.totalScheduledOrders?.count || 0;
            if (!totalScheduled) return null; // Hide if 0

            return (
              <div className="w-full flex items-center justify-between border border-gray-300 p-2 shadow-sm rounded-md overflow-x-auto no-scrollbar">
                {[
                  {
                    label: "Completed",
                    value: data?.completedOrdersSitDr?.count || 0,
                    to: navigationUrl(
                      data?.completedOrdersSitDr?.filterationRules,
                      "Completed Orders"
                    ),
                    icon: <FaCheckCircle className="text-green-600" />,
                  },
                  {
                    label: "Ongoing",
                    value: data?.outForServiceOrdersSitDr?.count || 0,
                    to: navigationUrl(
                      data?.outForServiceOrdersSitDr?.filterationRules,
                      "Ongoing"
                    ),
                    icon: <FaSyncAlt className="text-blue-600" />,
                  },
                  {
                    label: "Remaining",
                    value: data?.remainingOrdersSitDr?.count || 0,
                    to: navigationUrl(
                      data?.remainingOrdersSitDr?.filterationRules,
                      "Remaining scheduled Orders"
                    ),
                    icon: <FaHourglassHalf className="text-yellow-600" />,
                  },
                  {
                    label: "Scheduled",
                    value: totalScheduled,
                    to: navigationUrl(
                      data?.totalScheduledOrders?.filterationRules,
                      "Scheduled Orders"
                    ),
                    icon: <FaCalendarAlt className="text-blue-600" />,
                  },
                ].map((curr, index) => (
                  <div className="flex items-center" key={curr.label}>
                    <Link
                      to={curr.to}
                      onClick={updateUniversalFilter}
                      className="flex flex-col items-center text-center px-2"
                    >
                      <div className="flex items-center gap-1 text-xs md:text-sm font-medium text-gray-700">
                        {curr.icon}
                        <span>{curr.value}</span>
                      </div>
                      <div className="text-[7px] md:text-2xs text-gray-500 font-medium whitespace-nowrap">
                        {curr.label}
                      </div>
                    </Link>

                    {/* Connectors (+, =) between items */}
                    {index < 3 && (
                      <div className="mx-1 text-gray-400 text-[0.6rem] font-bold">
                        {index < 2 ? "+" : "="}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            );
          }}
          BodyItems={data?.totalScheduledOrders?.orders?.map((curr) => ({
            ...curr,
            to: `/order/order-list/custom?options=${encodeURIComponent(
              JSON.stringify({
                filterationRules: data?.totalScheduledOrders?.filterationRules,
                label: "Scheduled Orders",
                dateFrom: dateRange.from,
                dateTo: dateRange.to,
                dateRangeLabel: dateRange.label,
                city:
                  groupingFieldName === "city"
                    ? curr?.title
                    : storeStates?.hubs?.find(
                        (hub) => hub?.name === curr?.title
                      )?.city,
              })
            )}`,
          }))}
          BodyComp={BodyComp}
        />
      </div>
    </div>
  );
}

/**
 * OrderCountsSubCard
 * A reusable container for "Punched Orders" or "Scheduled Orders"
 */
const OrderCountsSubCard = ({
  className,
  HeaderComp,
  SubHeaderComp,
  BodyComp,
  BodyItems,
}) => {
  return (
    <div className={`flex flex-col gap-2 w-full ${className}`}>
      <HeaderComp />
      {SubHeaderComp && <SubHeaderComp />}
      {!!BodyItems?.length && (
        <div className="flex flex-col border border-gray-300 max-h-[7.7rem] rounded-md overflow-y-auto">
          {BodyItems.map((curr, index) => (
            <BodyComp key={index} index={index} item={curr} />
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * PunchedOrdersSubHeaderComp
 * Sub-stats for Punched Orders (i.e., "Same day" or "Santa").
 */
const PunchedOrdersSubHeaderComp = ({
  item,
  index,
  isReloading,
  className = "",
}) => {
  return (
    <Link
      key={index}
      to={item?.to}
      className={`flex items-center gap-2 px-3 py-1 border border-blue-100 rounded-full text-xs text-blue-700 cursor-pointer whitespace-nowrap ${className}`}
    >
      {isReloading ? (
        <div className="h-4 w-10 skeleton rounded-full" />
      ) : (
        <span className="font-medium">{item?.total || 0}</span>
      )}
      <span className="whitespace-nowrap text-[10px] md:text-xs text-blue-600">
        {item?.title}
      </span>
    </Link>
  );
};
