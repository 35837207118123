import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import { v2Api } from "../../../api/v2.api";

export default function UpdateEnquiryDetailsModal({
  parentStates,
  setParentStates,
}) {
  return (
    <AnimatePresence>
      {parentStates.updateEnquiryDetailsModal.isRender && (
        <Modal parentStates={parentStates} setParentStates={setParentStates} />
      )}
    </AnimatePresence>
  );
}

function Modal({ parentStates, setParentStates }) {
  const { data, onCancelCB, onSaveCB } = parentStates.updateEnquiryDetailsModal;
  const [pageState, setPageState] = useState({ ...data }); // enquery data
  const [admins, setAdmins] = useState(null);

  useEffect(() => {
    fetchAdmins();
    // eslint-disable-next-line
  }, []);

  async function fetchAdmins() {
    if (admins) return;
    const options = {
      tags: ["admin"],
      operation: "getAdmin",
      filters: {},
      queries: ["_id", "userName"],
    };
    try {
      const { isSuccess, data } = await v2Api(options);
      console.log(data);
      if (isSuccess) {
        setAdmins(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function resetState() {
    setParentStates((p) => ({
      ...p,
      updateEnquiryDetailsModal: {
        isRender: false,
      },
    }));
  }

  function getValue({ type, value }) {
    if (type === "date") {
      return moment(value).toDate();
    } else if (type === "number") {
      return parseInt(value, 10);
    }
    return value;
  }

  function handleOnChange(e) {
    const { name, type, value } = e.target;
    const splitName = name.split(".");

    const updatedData = { ...pageState };

    if (splitName.length > 1) {
      const [objectKey, fieldKey] = splitName;
      updatedData[objectKey] = {
        ...updatedData[objectKey],
        [fieldKey]: getValue({ type, value }),
      };
    } else {
      updatedData[name] = getValue({ type, value });
    }

    setPageState(updatedData);
  }

  function handleOnClose() {
    onCancelCB();
    resetState();
  }

  async function handleOnSave() {
    console.log(pageState);
    const { _id, user, ...res } = pageState;
    await onSaveCB(res);
    resetState();
  }
  // console.log(timeList)
  // console.log(pageState)

  return (
    <div className="fixed left-0 bottom-0 z-[999]">
      {/* background partial black layout */}
      <div
        onClick={handleOnClose}
        className="fixed inset-0 z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateY: 400, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        exit={{ translateY: 400, opacity: 0 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className="py-4 w-screen md:w-[30rem]  h-[95%] md:h-[70%] relative z-[2] flex flex-col rounded-lg bg-[white]  "
      >
        {/* heading */}
        <div className="flex justify-between items-center px-4">
          <div className=" font-semibold text-xl md:text-2xl">
            Update Enquiry Details
          </div>
          <RxCross2
            className="text-[1.25rem] cursor-pointer md:text-[2rem]"
            onClick={handleOnClose}
          />
        </div>

        <hr className="mt-3" />

        {/* details */}
        <div className="text-md gap-y-3 font-normal my-3 h-[70vh] overflow-y-auto px-4">
          <div className="flex flex-col">
            <label htmlFor="enquiryStatus">Status : </label>
            <select
              className="border p-2 rounded-lg w-full capitalize"
              id="enquiryStatus"
              name="enquiryStatus"
              value={pageState?.enquiryStatus}
              onChange={handleOnChange}
            >
              <option value="select-enquiryStatus">Select Status</option>
              {["open", "dropped", "converted", "scheduled", "pending"].map(
                (status, ind) => (
                  <option key={ind} value={status} className="capitalize">
                    {status}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="agent">Agent : </label>
            <select
              className="border p-2 rounded-lg w-full"
              id="agent"
              name="agent"
              value={pageState?.agent || data?.agent?._id}
              onChange={handleOnChange}
            >
              <option value="select-agent">Select Agent</option>
              {!!admins?.length &&
                admins.map((admin, ind) => (
                  <option key={ind} value={admin?._id}>
                    {admin?.userName}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="name">User : </label>
            <input
              className="border p-2 rounded-lg"
              id="name"
              name="name"
              type="text"
              value={pageState?.name}
              onChange={handleOnChange}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="phoneNumber">Phone no : </label>
            <input
              className="border p-2 rounded-lg"
              id="phoneNumber"
              name="phoneNumber"
              type="number"
              value={pageState?.phoneNumber}
              onChange={handleOnChange}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="reminderDate">Reminder Date : </label>
            <input
              className="border p-2 rounded-lg"
              id="reminderDate"
              name="reminderDate"
              type="date"
              value={moment(pageState?.reminderDate).format("YYYY-MM-DD")}
              onChange={handleOnChange}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="celebrationDate">Celebration Date : </label>
            <input
              className="border p-2 rounded-lg"
              id="celebrationDate"
              name="celebrationDate"
              type="date"
              value={moment(pageState?.celebrationDate).format("YYYY-MM-DD")}
              onChange={handleOnChange}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="readyTime">Ready Time : </label>
            <select
              className="border p-2 rounded-lg"
              id="readyTime"
              name="readyTime"
              value={pageState?.readyTime}
              onChange={handleOnChange}
            >
              {[...timeList, "12pm"].map((title, ind) => (
                <option key={ind} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <div>Budget : </div>
            <div className="flex gap-4">
              <input
                className="border p-2 rounded-lg w-full"
                id="budget.minPrice"
                name="budget.minPrice"
                type="number"
                value={pageState?.budget?.minPrice}
                onChange={handleOnChange}
              />
              <input
                className="border p-2 rounded-lg w-full"
                id="budget.maxPrice"
                name="budget.maxPrice"
                type="number"
                value={pageState?.budget?.maxPrice}
                onChange={handleOnChange}
              />
            </div>
          </div>
          {/* <div className="flex flex-col">
            <label htmlFor="notes">Notes : </label>
            <textarea className="border p-2 rounded-lg w-full h-[6rem]" id="notes" name="notes" type="text" value={pageState?.notes} onChange={handleOnChange} />
          </div> */}
          <div className="flex flex-col">
            <label htmlFor="trafficSource">Traffic Source : </label>
            <input
              className="border p-2 rounded-lg w-full"
              id="trafficSource"
              name="trafficSource"
              type="text"
              value={pageState?.trafficSource}
              onChange={handleOnChange}
            />
          </div>
          {/* <div className="flex flex-col">
            <label htmlFor="tags">Traffic Source : </label>
            <input className="border p-2 rounded-lg w-full" id="tags" name="tags" type="text" value={pageState?.trafficSource} onChange={handleOnChange} />
          </div> */}
        </div>

        {/* submit btn */}
        <button
          className=" bg-blue-600 text-white py-2 font-semibold rounded-lg mx-4 mt-2"
          onClick={handleOnSave}
        >
          Submit
        </button>
      </motion.div>
    </div>
  );
}

const timeList = Array(23)
  .fill(0)
  .map((_, ind) => ind + 1)
  .map((num) =>
    num === 12 ? num + "pm" : num < 12 ? num + "am" : (num % 12) + "pm"
  );
