/**
 * Analyzes the difference between a given ISO date string and the current date/time.
 *
 * @param {string} isoDateString - The ISO 8601 formatted date string to analyze.
 * @returns {DateAnalysisResult} An object containing detailed information about the date difference.
 *
 * @typedef {Object} DateAnalysisResult
 * @property {boolean} isFuture - Indicates if the given date is in the future (`true`) or past (`false`).
 * @property {TimeDifference} difference - The time difference broken down into years, months, days, hours, and minutes.
 * @property {string} shortLabel - A concise representation of the time difference using the two largest non-zero units (e.g., "2yrs3mo").
 * @property {string} timeDifferenceLabel - A descriptive label of the time difference with context (e.g., "2yrs3mo ago" or "5days4hrs left").
 * @property {string} givenDateISO - The original input date in ISO 8601 string format.
 * @property {string} currentDateISO - The current date and time in ISO 8601 string format.
 *
 * @typedef {Object} TimeDifference
 * @property {number} years - Number of full years in the difference.
 * @property {number} months - Number of remaining months after accounting for years.
 * @property {number} days - Number of remaining days after accounting for months.
 * @property {number} hours - Number of remaining hours after accounting for days.
 * @property {number} minutes - Number of remaining minutes after accounting for hours.
 *
 * @example
 * const result = analyzeDateDifference("2025-05-15T10:30:00Z");
 * console.log(result);
 * // {
 * //   isFuture: true,
 * //   difference: { years: 0, months: 3, days: 25, hours: 5, minutes: 45 },
 * //   shortLabel: "3mo25d",
 * //   timeDifferenceLabel: "3mo25d left",
 * //   givenDateISO: "2025-05-15T10:30:00.000Z",
 * //   currentDateISO: "2025-01-20T04:45:00.000Z"
 * // }
 */

// Ensure Moment.js is available
import moment from "moment"; // For ES Modules

export function analyzeDateDifference(isoDateString) {
  /**
   * Parses the ISO date string into a Moment.js object.
   * @type {moment.Moment}
   */
  const targetMoment = moment(isoDateString);

  /**
   * Retrieves the current date and time as a Moment.js object.
   * @type {moment.Moment}
   */
  const currentMoment = moment();

  /**
   * Determines if the target date is in the future relative to the current date.
   * @type {boolean}
   */
  const isFuture = targetMoment.isAfter(currentMoment);

  /**
   * Calculates the time difference between two Moment.js dates in terms of years, months, days, hours, and minutes.
   *
   * @param {moment.Moment} start - The start date.
   * @param {moment.Moment} end - The end date.
   * @returns {TimeDifference} The time difference components.
   */
  function calculateTimeDifference(start, end) {
    const difference = {};

    // Calculate years
    difference.years = end.diff(start, "years");
    start.add(difference.years, "years");

    // Calculate months
    difference.months = end.diff(start, "months");
    start.add(difference.months, "months");

    // Calculate days
    difference.days = end.diff(start, "days");
    start.add(difference.days, "days");

    // Calculate hours
    difference.hours = end.diff(start, "hours");
    start.add(difference.hours, "hours");

    // Calculate minutes
    difference.minutes = end.diff(start, "minutes");

    return difference;
  }

  /**
   * Determines the appropriate singular or plural label for a given time unit.
   *
   * @param {number} value - The numeric value of the time unit.
   * @param {string} unit - The time unit (e.g., 'year', 'month').
   * @returns {string} The formatted time unit string with proper pluralization and no space (e.g., "1yr", "2yrs").
   */
  function formatTimeUnit(value, unit) {
    const singularLabels = {
      years: "yr ",
      months: "mo ",
      days: "d ",
      hours: "hr ",
      minutes: "min ",
    };

    const pluralLabels = {
      years: "yrs ",
      months: "mos ",
      days: "d ",
      hours: "hrs ",
      minutes: "mins ",
    };

    if (value === 1) {
      return `${value}${singularLabels[unit]}`;
    } else {
      return `${value}${pluralLabels[unit]}`;
    }
  }

  /**
   * Generates a short label using the two largest non-zero time units.
   *
   * @param {TimeDifference} timeDiff - The time difference components.
   * @returns {string} The short label (e.g., "2yrs3mo").
   */
  function generateShortLabel(timeDiff) {
    const orderedUnits = ["years", "months", "days", "hours", "minutes"];
    const labelParts = [];

    for (const unit of orderedUnits) {
      if (timeDiff[unit] > 0) {
        labelParts.push(formatTimeUnit(timeDiff[unit], unit));
        if (labelParts.length === 2) break;
      }
    }

    // Fallback in case all units are zero
    return labelParts.length > 0 ? labelParts.join("") : "0min";
  }

  /**
   * Generates a descriptive label indicating the time difference with context.
   *
   * @param {string} shortLabel - The short label of the time difference.
   * @param {boolean} isFutureDate - Indicates if the target date is in the future.
   * @returns {string} The descriptive time difference label (e.g., "2yrs3mo ago", "5days4hrs left").
   */
  function generateTimeDifferenceLabel(shortLabel, isFutureDate) {
    const suffix = isFutureDate ? "left" : "ago";
    return `${shortLabel}${suffix}`;
  }

  // Calculate the time difference based on whether the target date is in the future or past
  const timeDifference = isFuture
    ? calculateTimeDifference(currentMoment.clone(), targetMoment.clone())
    : calculateTimeDifference(targetMoment.clone(), currentMoment.clone());

  const shortLabel = generateShortLabel(timeDifference);
  const timeDifferenceLabel = generateTimeDifferenceLabel(shortLabel, isFuture);

  return {
    isFuture,
    difference: timeDifference,
    shortLabel,
    timeDifferenceLabel,
    givenDateISO: targetMoment.toISOString(),
    currentDateISO: currentMoment.toISOString(),
  };
}

// Exporting the function for use in other modules (uncomment if using modules)
// module.exports = analyzeDateDifference;
// export default analyzeDateDifference;

// --------------------
// Example Usage:
// --------------------

// const result = analyzeDateDifference("2025-05-15T10:30:00Z");
// console.log(result);
/* Possible Output:
{
  isFuture: true,
  difference: { years: 0, months: 3, days: 25, hours: 5, minutes: 45 },
  shortLabel: "3mo25d",
  timeDifferenceLabel: "3mo25d left",
  givenDateISO: "2025-05-15T10:30:00.000Z",
  currentDateISO: "2025-01-20T04:45:00.000Z"
}
*/
