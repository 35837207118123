import { v2Api } from "../../api/v2.api";

const askAI = async ({
  prompt,
  operation = "askAI",
  context = {}, // { modelName: "MasterSheet", filter: { _id: "1" }, selects: ["_id", "itemName"], sort: { createdAt: -1 }, skip: 0, limit: 10 }
  responseType = "json" || "text",
  responseSchema = {},
  aiModelName = "gemini" || "chatGPT",
  onInitiate = () => {},
  onError = (msg) => {
    window.toast("Something went wrong", "error");
    // console.log(msg);
  },
  onSuccess = () => {},
}) => {
  try {
    onInitiate();

    const res = await v2Api({
      prompt,
      operation,
      context,
      responseType,
      responseSchema,
      aiModelName,
      tags: ["askAI"],
    });

    if (res.isSuccess) {
      onSuccess(res);
    } else {
      onError(res?.message ?? "Something went wrong");
    }
  } catch (error) {
    onError(error?.message);
    // console.error(error);
  }
};

/**
 * @doc: type object
 * @filter: type object
 * @update: type object
 * @options: type object
 * @operation: type string
 * @modelName: type string
 * @populates: type array
 * @pipeline: type array
 * @selects: type array
 * @sort: type object
 * @skip: type number
 * @limit: type number
 * @param {Object} {docs, filter, update, options, operation, modelName, populates, pipeline, selects, sort, skip, limit}
 */
class V2CrudOptionsModel {
  constructor({
    tags = [],
    docs = {},
    id = "",
    filter = {},
    update = {},
    options = {},
    operation = "read",
    modelName = "",
    populates = [],
    pipeline = [],
    selects = [],
    sort = { createdAt: -1 },
    skip = 0,
    limit = Math.pow(10, 6),
  }) {
    this.docs = docs; // {} doc will use while create a doc in database
    this.filter = filter; // {} doc will use while filtering a doc in database
    this.update = update; // {} doc will use while updating a doc in database
    this.options = options; // {} doc will use while updating a doc in database in options param
    this.operation = operation; // "read", "create", "update", "delete"
    this.modelName = modelName; // "Order", "User", "Video"
    this.populates = populates; // []
    this.pipeline = pipeline; // []
    this.selects = selects; // []
    this.sort = sort; // {}
    this.skip = skip; // number
    this.limit = limit; // number
    this.tags = tags || []; // number
    this.id = id; // number
  }
}

export default askAI;
