import { IoReload } from "react-icons/io5";
import {
  FaRegCalendar,
  FaUserPlus,
  FaUserCheck,
  FaUsers,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { v2Api } from "../../../../api/v2.api";
import { memo, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useStoreContext } from "../../../../context/StoreContext";
import DateCarousel from "../../../../assets/dateRangeSelectors/DateCarousel";
import FixedStats from "./c/FixedStats";
import CitySelectionC1 from "../../../citySelectionComponents/c1/CitySelectionC1";

const UserAnalyticsCard = memo(() => {
  const {
    storeStates: { universalFilter },
    setStoreStates,
  } = useStoreContext();

  const [states, setStates] = useState({
    orderCountData: null,
    dateFrom: moment().startOf("day").toDate(),
    dateTo: moment().endOf("day").toDate(),
    dateRangeLabel: "Today",
    isReloading: false,
    city: universalFilter.city,
    hub: universalFilter.hub,
    fixedStatsData: null,
    loadingFixedStats: true,
  });

  const fetchFixedStats = useCallback(async () => {
    setStates((p) => ({ ...p, loadingFixedStats: true }));

    const options = {
      tags: ["batchApi"],
      operations: [
        // yesterday data
        {
          operation: "getUserCountsForFilterOptions",
          data: {
            dateRange: {
              from: moment().startOf("day").subtract(1, "days").toDate(),
              to: moment().endOf("day").subtract(1, "days").toDate(),
            },
            queries: ["newUsers", "convertedUsers"],
            returnType: "total",
            city: states.city,
          },
          tags: ["user"],
        },
        // this week data
        {
          operation: "getUserCountsForFilterOptions",
          data: {
            dateRange: {
              from: moment().startOf("isoWeek").toDate(),
              to: moment().endOf("isoWeek").toDate(),
            },
            queries: ["newUsers", "convertedUsers"],
            returnType: "total",
            city: states.city,
          },
          tags: ["user"],
        },
        // last week data
        {
          operation: "getUserCountsForFilterOptions",
          data: {
            dateRange: {
              from: moment().startOf("isoWeek").subtract(1, "weeks").toDate(),
              to: moment().endOf("isoWeek").subtract(1, "weeks").toDate(),
            },
            queries: ["newUsers", "convertedUsers"],
            returnType: "total",
            city: states.city,
          },
          tags: ["user"],
        },
        // this month data
        {
          operation: "getUserCountsForFilterOptions",
          data: {
            dateRange: {
              from: moment().startOf("month").toDate(),
              to: moment().endOf("month").toDate(),
            },
            queries: ["newUsers", "convertedUsers"],
            returnType: "total",
            city: states.city,
          },
          tags: ["user"],
        },
      ],
    };

    const { data, isSuccess, message } = await v2Api(options);

    if (!isSuccess) {
      window.toast(message, "success");
    } else {
      console.log("fixedStatsData", data);
      setStates((p) => ({
        ...p,
        fixedStatsData: {
          yesterday: data[0]?.send?.data,
          thisWeek: data[1]?.send?.data,
          lastWeek: data[2]?.send?.data,
          thisMonth: data[3]?.send?.data,
        },
      }));
    }

    setStates((p) => ({ ...p, loadingFixedStats: false }));

    // eslint-disable-next-line
  }, [states.city]);

  useEffect(() => {
    fetchFixedStats();

    // eslint-disable-next-line
  }, [states.city]);

  const onInitialLoad = useCallback(async () => {
    setStates((p) => ({ ...p, isReloading: true }));

    const options = {
      tags: ["user"],
      operation: "getUserCountsForFilterOptions",
      dateRange: {
        from: states.dateFrom,
        to: states.dateTo,
      },
      queries: ["newUsers", "activeUsers", "convertedUsers"],
      returnType: "total",
    };

    if (states.city !== "all") {
      options.city = states.city;
    }

    const { data, isSuccess, message } = await v2Api(options);

    if (!isSuccess) {
      window.toast(message, "success");
    } else {
      setStates((p) => ({
        ...p,
        orderCountData: data,
      }));
    }

    setStates((p) => ({ ...p, isReloading: false }));
  }, [states.city, states.dateFrom, states.dateTo]);

  const updateUniversalFilter = useCallback(() => {
    setStoreStates((p) => ({
      ...p,
      universalFilter: {
        city: states.city,
        hub: states.hub,
      },
    }));
  }, [setStoreStates, states.city, states.hub]);

  useEffect(() => {
    onInitialLoad();

    //  eslint-disable-next-line
  }, [states.city, states.dateFrom, states.dateTo]);

  useEffect(() => {
    setStates((p) => ({
      ...p,
      city: universalFilter.city,
      hub: universalFilter.hub,
    }));
  }, [universalFilter.city, universalFilter.hub]);

  const navigationUrl = useCallback(
    ({ queries, label, sortBy, dateRange }) => {
      return `/user/list?filter=${encodeURIComponent(
        JSON.stringify({
          queries,
          dateFrom: dateRange?.[0] ?? states.dateFrom,
          dateTo: dateRange?.[1] ?? states.dateTo,
          dateRangeLabel: dateRange?.[2] ?? states.dateRangeLabel,
          label,
          sortBy,
        })
      )}`;
    },
    [states.dateFrom, states.dateRangeLabel, states.dateTo]
  );

  return (
    <div className="flex flex-col border border-gray-200 bg-white rounded-lg size-full py-4">
      <div className="w-full flex flex-wrap gap-2 justify-between items-center border-b px-4 pb-2 font-[600] mb-4">
        {/* Title and City/Hub */}
        <div className="flex flex-col">
          <h1 className="font-medium">User Analytics</h1>
          {states?.hub !== "all" && (
            <h2 className="text-2xs md:text-xs capitalize">
              {states?.city}
              {` - ${states?.hub}`}
            </h2>
          )}

          <CitySelectionC1
            selectedCity={states.city}
            callback={(selectedCity) => {
              setStates((p) => ({ ...p, city: selectedCity, hub: "all" }));
            }}
          />
        </div>

        {/* Reload & Date Range Picker */}
        <div className="gap-4 flex items-center">
          {states.isReloading ? (
            <div className="loader w-[1rem]" />
          ) : (
            <IoReload
              onClick={() => onInitialLoad()}
              className="cursor-pointer text-[1.125rem]"
            />
          )}

          <div
            onClick={() => {
              window.setDateRange({
                type: "userAnalyticsCardDateRange",
                callBack: (r) => {
                  setStates((p) => ({
                    ...p,
                    dateFrom: r.from,
                    dateTo: r.to,
                    dateRangeLabel: r.label,
                  }));
                },
              });
            }}
            className="border cursor-pointer bg-white shadow-md rounded-md flex gap-2 items-center text-[.825rem] px-2 py-1"
          >
            {states.dateRangeLabel} <FaRegCalendar />
          </div>
        </div>
      </div>

      {moment(states.dateFrom).isSame(moment(states.dateTo), "day") && (
        <div className="w-full flex-center pt-2 pb-4 ">
          <div className="w-[30rem] max-w-[80vw]">
            <DateCarousel
              {...{
                dateRange: [
                  moment(states.dateFrom),
                  moment(states.dateTo),
                  states.dateRangeLabel,
                ],
                onChangeCallback: (dR) => {
                  setStates((p) => ({
                    ...p,
                    dateFrom: dR[0].toDate(),
                    dateTo: dR[1].toDate(),
                    dateRangeLabel: dR[2],
                  }));
                },
              }}
            />
          </div>
        </div>
      )}

      {/* Loading Skeleton */}
      {states.isReloading ? (
        <div className="w-full h-[17rem] flex flex-col gap-4 justify-center px-4">
          {Array(3)
            .fill(0)
            .map((_, idx) => (
              <div key={idx} className="skeleton h-[6rem] rounded-lg" />
            ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 px-4">
          {/* New Users */}
          <Link
            to={navigationUrl({ queries: ["newUsers"], label: "New users" })}
            onClick={updateUniversalFilter}
            className="flex flex-col items-start justify-center px-4 py-3 rounded-lg shadow-sm bg-blue-50 hover:bg-blue-100 transition-colors"
          >
            <div className="flex items-center gap-2 mb-1">
              <FaUserPlus className="text-blue-700 text-lg" />
              <span className="text-gray-700 text-sm font-medium">
                New users
              </span>
            </div>
            <div className="text-2xl font-bold text-blue-800">
              {states.orderCountData?.newUsers || 0}
            </div>
          </Link>

          {/* Converted Users */}
          <Link
            to={navigationUrl({
              queries: ["convertedUsers"],
              label: "Converted Users",
              sortBy: "registrationDesc",
            })}
            onClick={updateUniversalFilter}
            className="flex flex-col items-start justify-center px-4 py-3 rounded-lg shadow-sm bg-purple-50 hover:bg-purple-100 transition-colors"
          >
            <div className="flex items-center gap-2 mb-1">
              <FaUserCheck className="text-purple-700 text-lg" />
              <span className="text-gray-700 text-sm font-medium">
                Converted Users
              </span>
            </div>
            <div className="text-xl font-semibold text-purple-800 flex items-baseline gap-1">
              {states.orderCountData?.convertedUsers || 0} /{" "}
              {states.orderCountData?.newUsers || 0}
              <span className="text-xs text-gray-600">converted</span>
            </div>
          </Link>

          {/* Active Users */}
          <Link
            to={navigationUrl({
              queries: ["activeUsers"],
              label: "Active Users",
              sortBy: "lastLoginTimeDesc",
            })}
            onClick={updateUniversalFilter}
            className="flex flex-col items-start justify-center px-4 py-3 rounded-lg shadow-sm bg-green-50 hover:bg-green-100 transition-colors"
          >
            <div className="flex items-center gap-2 mb-1">
              <FaUsers className="text-green-600 text-lg" />
              <span className="text-gray-700 text-sm font-medium">
                Active users
              </span>
            </div>
            <div className="text-2xl font-bold text-green-800">
              {states.orderCountData?.activeUsers || 0}
            </div>
          </Link>
        </div>
      )}
      {states.loadingFixedStats && !states.fixedStatsData ? (
        <div className="w-full h-[17rem] mt-3 flex flex-col gap-4 justify-center px-4">
          {Array(3)
            .fill(0)
            .map((_, idx) => (
              <div key={idx} className="skeleton h-[6rem] rounded-lg" />
            ))}
        </div>
      ) : (
        <FixedStats
          {...{ states, setStates, navigationUrl, updateUniversalFilter }}
        />
      )}
    </div>
  );
});

export default memo(UserAnalyticsCard);
