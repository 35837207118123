import React from "react";
import { useStoreContext } from "../../../../../../../context/StoreContext";

export default function ServiceableCities({ states, setStates }) {
  const {
    storeStates: { serviceableCities },
  } = useStoreContext();

  if (states.showServiceCites) {
    return (
      <div className="flex flex-col w-full bg-gradient-to-b rounded-xl px-4 mt-2  from-blue-50 to-white">
        <div className="w-full text-[.875rem] px-1  text-[grey] pt-2  ">
          Serviceable city
        </div>
        {/* serviceable cities container */}

        <div className="flex  text-[.875rem] gap-4 flex-wrap justify-between mt-2 w-full">
          {Object.values(serviceableCities).map((curr) => (
            <div
              onClick={() => {
                setStates((p) => ({
                  ...p,
                  lat: curr.location.lat,
                  lng: curr.location.lng,
                  showMap: true,
                  defaultUi: false,
                  isPredictionsShow: false,
                  loadingPredictions: false,
                  showServiceCites: false,
                  isChangeMapPosition: true,
                }));
              }}
              className="flex relative min-w-[30%] items-center gap-2 flex-col "
            >
              <img
                className="rounded-xl object-cover w-[96px] h-[66px]"
                src={curr.imageUrl}
                alt="city"
              />
              <span>{curr.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
